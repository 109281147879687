<template>
    <div>
        <!-- Banner Section start -->
        <section class="lens-banner-wrapper" style="height: 580px">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7">
                        <div class="lens-banner-info">
                            <h2>想你所想 为你服务</h2>
                            <h3>专业化的房产服务平台 国资背景</h3>
                            <p>e指找房 租房无忧</p>
                            <!-- Search Box -->
                            <div class="search-box">
                                <div class="search-box-container">
                                    <div class="search-bar-inner">
                                        <input type="text" placeholder="查询房源..." disabled />
                                        <a href="/allHouse"><i class="fa fa-search"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="lens-btn-wrap">
                                <!-- <a href="shop.html" class="lens-btn">
                                      查看视频
                                  </a> -->
                                <a class="lens-btn lens-btn-outline" @click="aboutUs">
                                    关于我们
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                        <div class="lens-banner-img">
                            <img src="@/assets/banner-img.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Feature Start -->
        <div class="container-fluid feature bg-light py-5">
            <div class="container py-5">
                <div class="row g-4">
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                        <div class="feature-item p-4 pt-0">
                            <div class="feature-icon p-4 mb-4">
                                <i class="fa fa-home fa-3x"></i>
                            </div>
                            <h4 class="mb-4">全部房源</h4>
                            <p class="mb-4">查询全部招租房源合计58处房源</p>
                            <a class="btn btn-primary rounded-pill py-2 px-4" href="/allHouse">点击前往</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.2s">
                        <div class="feature-item p-4 pt-0">
                            <div class="feature-icon p-4 mb-4">
                                <i class="fa fa-map-marker-alt fa-3x"></i>
                            </div>
                            <h4 class="mb-4">地图找房</h4>
                            <p class="mb-4">地图寻找合适的招租房源</p>
                            <a class="btn btn-primary rounded-pill py-2 px-4" href="/mapFindHouse">点击前往</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="feature-item p-4 pt-0">
                            <div class="feature-icon p-4 mb-4">
                                <i class="fa fa-handshake fa-3x"></i>
                            </div>
                            <h4 class="mb-4">帮我找房</h4>
                            <p class="mb-4">委托我们帮您找到合适的房源</p>
                            <a class="btn btn-primary rounded-pill py-2 px-4" href="/helpFindHouse">点击前往</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.8s">
                        <div class="feature-item p-4 pt-0">
                            <div class="feature-icon p-4 mb-4">
                                <i class="fa fa-users fa-3x"></i>
                            </div>
                            <h4 class="mb-4">服务网点</h4>
                            <p class="mb-4">查询各个线下服务网点</p>
                            <a class="btn btn-primary rounded-pill py-2 px-4" href="/servicePoint">点击前往</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Feature End -->

        <!-- Blog Start -->
        <div class="container-fluid blog py-2" style="margin-top: 30px">
            <div class="container py-2">
                <div class="text-center mx-auto pb-2" data-wow-delay="0.2s" style="max-width: 800px">
                    <h1 class="display-4 mb-2">房源推荐</h1>
                </div>
                <span style="
                        font-size: 26px;
                        margin-bottom: 40px;
                        font-weight: bold;
                        color: black;
                    ">精选商铺</span>
                <span style="
                        font-size: 26px;
                        margin-left: 5px;
                        margin-right: 5px;
                        margin-bottom: 40px;
                        color: black;
                    ">财富启航</span>
                <p style="
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        margin-bottom: 12px;
                    ">
                    <span style="color: #666; font-size: 15px">黄金旺铺，绝佳位置助你生意兴隆。</span>
                    <a href="/shop" style="color: #666; font-size: 15px; font-family: Microsoft YaHei">查看更多</a>
                </p>
                <HouseItem :houseEntity="houseEntity"></HouseItem>
            </div>
        </div>
        <div class="container-fluid blog py-2">
            <div class="container py-2">
                <div class="text-center mx-auto pb-3" data-wow-delay="0.2s" style="max-width: 800px"></div>
                <span style="
            font-size: 26px;
            margin-bottom: 40px;
            font-weight: bold;
            color: black;
          ">写字楼臻选</span>
                <span style="
            font-size: 26px;
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 40px;
            color: black;
          ">事业高飞</span>
                <p style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 12px;
          ">
                    <span style="color: #666; font-size: 15px">商务首选，优越环境助力企业发展。</span>
                    <a href="/officeBuilding" style="color: #666; font-size: 15px; font-family: Microsoft YaHei">查看更多</a>
                </p>
                <HouseItem :houseEntity="write"></HouseItem>
            </div>
        </div>
        <div class="container-fluid blog py-2">
            <div class="container py-2">
                <span style="
            font-size: 26px;
            margin-bottom: 40px;
            font-weight: bold;
            color: black;
          ">厂房优选</span>
                <span style="
            font-size: 26px;
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 40px;
            color: black;
          ">生产助力</span>

                <p style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 12px;
          ">
                    <span style="color: #666; font-size: 15px">生产无忧，合理布局提升企业效益。</span>
                    <a href="/factory" style="color: #666; font-size: 15px; font-family: Microsoft YaHei">查看更多</a>
                </p>
                <HouseItem :houseEntity="work" style="margin-bottom: 40px;"></HouseItem>
            </div>
        </div>
        <!-- Blog End -->

        <div class="container-fluid feature bg-light py-5">
            <div class="container-fluid blog py-0">
                <div class="container py-0">
                    <div class="text-center mx-auto pb-3" data-wow-delay="0.2s" style="max-width: 800px">
                        <h1 class="display-4 mb-4">合作伙伴</h1>
                        <span style="
                            font-size: 22px;
                            font-family: Microsoft YaHei;
                            color: #646464;
                            margin-left: 5px;
                            margin-right: 5px;
                        ">欢迎加入e指找房合作伙伴生态，与我们共同优化小微企业营商环境。</span>
                        <a class="btn btn-primary rounded-pill py-3 px-5" style="margin-top: 24px; margin-bottom: 24px"
                            >加入我们</a>
                    </div>

                    <div class="row g-4">
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div href="category.html" class="lens-game-catergoy">
                                <div class="lens-game-catergoy-inner">
                                    <div class="lens-catergory-icon">
                                        <span>
                                            <img src="@/assets/company.jpg" style="height: 160px" alt="" />
                                        </span>
                                    </div>
                                    <h4>上海市金山区供销合作社</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div href="category.html" class="lens-game-catergoy">
                                <div class="lens-game-catergoy-inner">
                                    <div class="lens-catergory-icon">
                                        <span>
                                            <img src="@/assets/yth.jpg" style="height: 160px" alt="" />
                                        </span>
                                    </div>
                                    <h4>资产经营“一体化”企业</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        <!-- Blog End -->
        </div>
        <a-modal :width="500" :footer="null" :visible="visible" :closable="true" @cancel="closeMoudle" :zIndex="999">
            <video width="450" height="300" controls>
                <source src="https://gxscloud.oss-cn-shanghai.aliyuncs.com/efind/video/eFindHouse.mp4" type="video/mp4"
                    autoplay>
                您的浏览器不支持视频标签。
            </video>
        </a-modal>
    </div>
</template>

<script>
import { recommendHouse } from "@/request/api";
import HouseItem from '@/components/property/HouseItem'
export default {
    name: "Home",
    components: {
        HouseItem
    },
    data() {
        return {
            houseEntity: [],
            spin: 0,
            write: [],
            work: [],
            visible: false,
        };
    },
    mounted() {
        recommendHouse({
            houseType: [1],
            page: 1,
            pageSize: 4,
        }).then((res) => {
            this.houseEntity = res.data.data;
            this.spin = 1;
        });

        recommendHouse({
            houseType: [2],
            page: 1,
            pageSize: 4,
        }).then((res) => {
            this.write = res.data.data;
            this.spin = 1;
        });

        recommendHouse({
            houseType: [4],
            page: 1,
            pageSize: 4,
        }).then((res) => {
            this.work = res.data.data;
            this.spin = 1;
        });
    },
    methods: {
        closeMoudle() {
            this.visible = false
        },
        aboutUs() {
            this.visible = true
        }
    },
};
</script>

<style scoped></style>