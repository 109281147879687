<template>
    <div class="row g-4">
        <div class="col-md-6 col-lg-6 col-xl-3" data-wow-delay="0.2s" v-for="(item, index) in houseEntity" :key="index">
            <a class="blog-item" @click="onclick(item)">
                <div class="blog-img">
                    <div v-if="item.houseType == 4">
                        <img :src="item.housePlanformUrl[0]+'?x-oss-process=image/resize,l_800'" class="img-fluid rounded-top w-100" alt="" style="height: 250px" />
                    </div>
                    <div v-else>
                        <img :src="item.housePic[0]+'?x-oss-process=image/resize,l_800'" class="img-fluid rounded-top w-100" alt="" style="height: 250px" />
                    </div>
                    
                </div>
                <div class="blog-content p-3">
                    <span class="h5 d-inline-block mb-1" style="margin-top: 2px;
				font-weight:bold;
				font-size:18px;
				font-family: Arial;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				height: 42px;
                text-overflow: ellipsis;">{{
                    item.address
                        }}</span>
                    <div class="blog-comment d-flex justify-content-between mb-1">
                        <span style="
                      font-size: 14px;
                      font-family: Arial;
                      color: #646464;
                      font-weight:bold;
                      margin-left: 2px;
                    ">{{ item.placeTown == 1 ? "金山区 朱泾镇"
                        : item.placeTown == 2 ? "金山区 枫泾镇"
                            : item.placeTown == 3 ? "金山区 张堰镇"
                                : item.placeTown == 4 ? "金山区 亭林镇"
                                    : item.placeTown == 5 ? "金山区 吕巷镇"
                                        : item.placeTown == 6 ? "金山区 廊下镇"
                                            : item.placeTown == 7 ? "金山区 金山卫镇"
                                            : item.placeTown == 8 ? "金山区 漕泾镇"
                                            : item.placeTown == 9 ? "金山区 山阳镇"
                                            : item.placeTown == 10 ? "金山区 石化街道"
                                            : item.placeTown == 11 ? "上海市区房源"
                            : "其他地区"}}</span>
                        <span style="
                      font-size: 14px;
                      font-family: Arial;
                      color: #646464;
                      font-weight:bold;
                    ">{{ item.rentableSpace }}㎡
                        </span>
                    </div>
                    <div class="blog-comment row justify-content-between align-items-center">
                        <div class="col-md-7">
                            <div style="
                            background-color: #e8e8e8;
                            color: #646464;
                            display: inline-block;
                            padding: 2px 5px;
                            border-radius: 3px;
                            ">
                                国企认证
                            </div>
                            <div style="
                            background-color: #e8e8e8;
                            color: #646464;
                            display: inline-block;
                            padding: 2px 5px;
                            border-radius: 3px;
                            margin: 4px 6px;
                            ">
                                自营房源
                            </div>
                            <div style="
                            background-color: #e8e8e8;
                            color: #646464;
                            display: inline-block;
                            padding: 2px 5px;
                            border-radius: 3px;
                            margin: 4px 0px;
                            ">
                                {{ item.houseDescribe }}
                            </div>
                        </div>
                        <div class="col-md-5 d-flex justify-content-end">
                            <span v-if="item.price > 0"
                                style="font-weight:bold;font-size:15px;font-family: Arial;color: #ff0214;">￥{{ item.price }}元/(㎡·天)</span>
                            <span v-else style="
                                font-weight: bold;
                                font-size: 15px;
                                font-family: Arial;
                                color: #ff0214;
                                ">
                                价格面议
                            </span>

                        </div>

                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "HouseItem",
    props: {
        houseEntity: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
        };
    },
    methods: {
        onclick(item){
            var assetId = item.id

            // 使用 this.$router.resolve 生成带参数的 URL
        let url = this.$router.resolve({
            name: 'houseDetail',
            query: { assetId }
        }).href;

        // 使用 window.open 在新标签页中打开 URL
        window.open(url, '_blank');
        }
    }
}
</script>

<style></style>